.blog-home-container {
    box-sizing: border-box;
    padding-bottom: 50px;
}

.blog-home-container * {
    box-sizing: border-box;
}

.blog-home {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0;
    padding-top: 40px;
    padding-bottom: 40px;
}

.blog-list {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.blog-list .container {
    max-width: 100%;
}

.blog-list-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 50px;
    row-gap: 80px;
    justify-content: center;
    justify-items: center;
}

.blog-item {
    width: 40vw;
    height: 850px;
    position: relative;
    background: #FFFFFF;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 2px 4px 8px 0 #0000000a;
}

.blog-item p {
    font-family: "Sweet Sans Pro Med", 'Roboto', sans-serif !important;
}

.blog-item .blog-image img {
    max-width: 100%;
}

.blog-item .blog-inner-item {
    padding-top: 30px;
    overflow-wrap: anywhere;
}

.blog_title {
    overflow-wrap: anywhere;
}

.blog-item .view-button {
    position: absolute;
    bottom: 20px;
    right: 0;
    width: 100%;
    text-align: center;
}

.blog-item .view-button a {
    width: 70%;
    background: linear-gradient(90deg, #C6B166 0%, #A9955E 100%);
    font-weight: bold;
    background-position: left;
    transition: 0.5s background-position ease-out;
    background-size: 300%;
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
}

.blog-item .view-button a:hover {
    background-position: right;
}

.blog-item .blog-inner-item .post-details-bottom:not(span) {
    font-weight: bold;
}

.blog-item .blog-inner-item p span {
    color: #C6B166 !important;
}

.full_post #mobile-edit-button {
    display: none;
}

.full_post img {
    margin-top: 50px;
    margin-bottom: 80px;
}

.full_post p span {
    color: #C6B166 !important;
}

.full_post, .blog-item {
    color: #454B51;
}

.full_post p {
    font-size: 16px;
}

.full_post_info {
    display: flex;
    justify-content: space-between;
}

.blog-item .post-details-bottom {
    display: flex;
    justify-content: space-between;
}

.post-form-container {
    padding-bottom: 30px;
}

.blog-item h3 a, .full_post h2 {
    color: #305C6E;
}

#mobile-navbar-breakline {
    display: none;
}

@media screen and (max-width: 1024px) {
    .blog-list-grid {
        display: grid;
        grid-template-columns: 1fr;
        row-gap: 50px;
    }

    .blog-item {
        height: auto;
        width: 90vw;
        padding-bottom: 100px;
        box-shadow: 4px 2px 10px 0 rgba(0,0,0,0.1);
    }

    .full_post img {
        width: 100%;
        margin-top: 30px;
        margin-bottom: 50px;
    }

    #single_post {
        box-sizing: border-box;
    }

    .full_post #mobile-edit-button {
        display: block;
        margin-bottom: 20px;
        margin-left: auto;
        width: fit-content;
    }

    .full_post #desktop-edit-button {
        display: none;
    }

    .admin-post-card img {
        width: 100%;
    }

    .admin-post-card-delete {
        margin-top: 20px;
        width: 100%;
    }

    .admin-app .navbar-brand {
        left: auto;
        right: 10px;
        top: 5px;
        background: none;
    }

    .admin-app .navbar-toggler {
        background: #3193F5;
        z-index: 1000;
    }

    .admin-app .navbar-links li {
        padding-left: 5px !important;
    }

    .admin-app #sidebar-root {
        display: block;
        margin: 0;
    }

    .admin-app .active-sidebar {
        display: none !important;
    }

    #mobile-navbar-breakline {
        display: block;
        margin-top: 5px;
    }

    .blog-app {
        overflow: hidden;
    }

    .blog-app, .blog-app * {
        box-sizing: border-box;
    }
}

@media screen and (min-width: 1920px) {
    .blog-list-grid {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
    }

    .blog-item {
        width: 30vw;
    }
}
